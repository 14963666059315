import { Routes } from '@angular/router';
import { OndcSiteVerificationComponent } from './ondc-site-verification/ondc-site-verification.component';
import { HomePageComponent } from './home-page/home-page.component';

export const routes: Routes = [
  { path: '', component: HomePageComponent }, // Default home page route
  {
    path: 'ondc-site-verification.html',
    component: OndcSiteVerificationComponent,
  },
  { path: '**', redirectTo: '/' },
];
