<!--Contents of ondc-site-verification.html. -->
<!--Please replace SIGNED_UNIQUE_REQ_ID with an actual value-->
<html>
  <head>
    <meta
      name="ondc-site-verification"
      content="V9sVdurqKwh2Uzpv+0ue/7Qbpp9TOvUSC5bzrl96fwleFZGEWs/KY0RQAQp9xRSglugFwLVE7S7PZhykx+vjCg=="
    />
  </head>
  <body>
    ONDC Site Verification Page
  </body>
</html>
